import React from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import { cn } from "../lib/helpers";
import Layout from "../containers/layout";
import Container from "../components/container";

import * as styles from "../styles/components/page-404.module.css";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not found" />
    <Container>
      <div className={cn("grid", styles.root)}>
        <div className="col-12 col-md-6">
          <h1>404</h1>
          <p>We can’t find the page you’re looking for</p>
          <p><a href="/" className="view--more">Return Home</a></p>
        </div>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
